import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import shortid from 'shortid';
import {useForm} from '../../../../hooks/useForm';
import {useUpdateAssInPackage} from '../../../../hooks/useUpdateAssInPackage';
import {AppStore} from '../../../../store/applicationState';
import {
  CreateAssessment,
  EditAssessment,
  GetOneAssessment,
  setAssEditMode,
} from '../../../../store/assessments/actions';
import {
  EQuestionType,
  TAssessment,
  TAssessmentCreate,
  TCatAssessment,
  TQuestion,
  TQuestionType,
} from '../../../../store/assessments/types';
import {GetCats} from '../../../../store/categories/actions';
import {TCat} from '../../../../store/categories/types';
import {setPackage} from '../../../../store/packages/actions';
import {TPackage} from '../../../../store/packages/types';
import {SELECT_TYPES, SHORT_ID_CHARS} from '../../../../utils/consts';
import {deepClone} from '../../../../utils/deepClone';
import {TEXT} from '../../../../utils/textConst';
import {SelectItemType} from '../../../ui/selects/DefaultSelect';
import {callBackCreate} from './callBackCreate';
import {callBackEdit} from './callBackEdit';
import {DataOpenFeedback} from "../../data/workspaceData";

export interface QuestionsType {
  title: string;
  id: number;
  hasPass: boolean;
  answer_type: TQuestionType;

}

const TITLE = 'title';
const DESC = 'description';
const CODE = 'code';
shortid.characters(SHORT_ID_CHARS);

export const useNewAssessment = (
  closeModal: () => void,
  team_id: string,
  cats: TCat[],
  assessment?: TAssessment,
  packages?: TPackage[],
  answer_type?: EQuestionType
) => {
  const { form, onChangeHandler, updateForm } = useForm({
    [TITLE]: {
      value: assessment ? assessment.name.en_US : '',
    },
    [DESC]: {
      value: assessment ? assessment.description.en_US : '',
    },
    [CODE]: {
      value: assessment ? assessment.code : shortid.generate(),
    },
  });
  const { Packages } = useSelector((store: AppStore) => store);
  const [questions, setQuestions] = React.useState<QuestionsType[]>([]);
  const [warning, setWarning] = React.useState('');
  const [cat, setCat] = React.useState<SelectItemType | null>(null);
  const [typeSelect, setTypeSelect] = React.useState<SelectItemType>(
    SELECT_TYPES[0]
  );
  const dispatch = useDispatch();
  const { updateAssInPackage } = useUpdateAssInPackage();

  React.useEffect(() => {
    dispatch(GetCats.error(''));
  }, [dispatch]);

  React.useEffect(() => {
    setWarning('');
    return () => {
      dispatch(GetCats.error(''));
    };
  }, [cat, dispatch]);

  React.useEffect(() => {
    if (assessment) {
      const newQ: QuestionsType[] = [];

      assessment.questions.forEach((q) =>
        newQ.push({
          title: q.body.en_US,
          id: q.index,
          answer_type: q.answer_type,
          hasPass: true,
        })
      );

      setQuestions(newQ);
    }
  }, [assessment]);

  const addQuestion = () => {
    setQuestions((oldQ) => [
      ...oldQ,
      {
        title: '',
        id: questions.length + 1,
        hasPass: true,
        answer_type: EQuestionType.five,
      },
    ]);
  };

  const addQuestionLinkSurvey = () => {
   if (questions.length !== 0) {
     return
   }
    setQuestions((oldQ) => [
      ...oldQ,
      {
        title: '',
        id: questions.length + 1,
        hasPass: true,
        answer_type: EQuestionType.linked_survey,
      },
    ]);
  };

  const removeQuestion = (data: { id: number; position: number }) => {
    const newQ = [...questions];
    newQ.splice(data.position, 1);
    setQuestions(newQ);
  };

  const titleHandler = (data: {
    title: string;
    position: number;
    id: number;
  }) => {
    const { title, position } = data;
    const oldQ = [...questions];
    oldQ[position].title = title;
    setQuestions(oldQ);
  };

  const removePass = (data: {
    id: number;
    position: number;
    removed: boolean;
  }) => {
    const { position, removed } = data;
    const oldQ = [...questions];
    oldQ[position].hasPass = removed;
    setQuestions(oldQ);
  };

  const reverseHandler = (data: {
    id: number;
    position: number;
    reversed: boolean;
  }) => {
    const { position, reversed } = data;
    // console.log('reverse handler');
    // console.log(reversed);

    const oldQ = [...questions];
    oldQ[position].answer_type = reversed
      ? EQuestionType.five_reverse
      : EQuestionType.five;
    // console.log(oldQ[position]);
    setQuestions(oldQ);
  };

  const textAnonymousHandler = (data: {
    id: number;
    position: number;
    isAnonymous: boolean;
  }) => {
    const { position, isAnonymous } = data;
    // console.log('reverse handler');
    // console.log(reversed);

    const oldQ = [...questions];
    oldQ[position].answer_type = isAnonymous
        ? EQuestionType.text_anonymous
        : EQuestionType.text;
    // console.log(oldQ[position]);
    setQuestions(oldQ);
  };

  const textOr1To5Handler = (data: {
    id: number;
    position: number;
    type: number;
  }) => {
    const { position, type } = data;
    // console.log('reverse handler');
    // console.log(reversed);

    const oldQ = [...questions];

    oldQ[position].answer_type = type === DataOpenFeedback[0].id
        ? EQuestionType.text_anonymous
        : EQuestionType.five;
    // console.log(oldQ[position]);
    setQuestions(oldQ);
  };

  const updatePack = (ass: TCatAssessment) => {
    const newPack = deepClone(Packages.current) as TPackage;
    const newAss = newPack.assessments.find(
      (newAssItem) => newAssItem.id === ass.id
    );
    if (!newAss) return console.error('Could not find assessment in package');
    newAss.name = ass.name.en_US;
    newAss.number_of_questions = ass.number_of_questions;
    return dispatch(setPackage(newPack));
  };

  const submitHandler = () => {
    const name = form[TITLE].value;
    const code = form[CODE].value;
    const description = form[DESC].value;
    if (!team_id) {
      return setWarning(TEXT.noTeamId);
    }

    if (!name) {
      return updateForm(TITLE, { value: '', errorText: TEXT.isRequired });
    }
    if (!cat?.value) {
      return setWarning('Category is required');
    }
    if (!code) {
      return updateForm(CODE, { value: '', errorText: TEXT.isRequired });
    }
    const assQError = 'In assessment should be at least one question';
    const assLSError = 'Please, enter the correct link with https:// or  http:// and the domain name';
    if (!questions[0]) {
      if (answer_type === EQuestionType.linked_survey) {
        return setWarning(assLSError);
      } else {
        return setWarning(assQError);
      }

    }
    if (!questions[0].title) {
      if (answer_type === EQuestionType.linked_survey) {
        return setWarning(assLSError);
      } else {
        return setWarning(assQError);
      }
    }

    const formatSQ: TQuestion[] = questions.map((q, i) => ({
      index: i + 1,
      body: {
        en_US: q.title,
      },
      answer_type: q.answer_type,
    }));

    const data: TAssessmentCreate = {
      name: {
        en_US: name,
      },
      description: {
        en_US: description,
      },
      type: typeSelect.value,
      answer_type: EQuestionType.five,
      code,
      team_id,
      category_id: cat.value,
      questions: formatSQ,
    };

    if (assessment) {
      console.log('Assessment here');
      console.log(assessment);
      console.log(data);
      return dispatch(
        EditAssessment.request({
          data,
          id: assessment.id,
          callBack: (success, data) => {
            const newData = callBackEdit({
              success,
              data,
              cats,
              assessment,
              name,
            });
            if (!newData) return null;
            const { newCasts, ass } = newData;
            if (Packages.current) updatePack(ass);
            dispatch(setAssEditMode(false));
            dispatch(EditAssessment.success(newCasts));
            updateAssInPackage(ass);
            return dispatch(GetOneAssessment.success({}));
          },
        })
      );
    }

    return dispatch(
      CreateAssessment.request({
        data,
        callBack: (success, data) => {
          const newData = callBackCreate({
            success,
            data,
            cats,
            name,
            questions,
          });
          if (!newData) return null;
          const { newCasts } = newData;
          dispatch(CreateAssessment.success(newCasts));
          return closeModal();
        },
      })
    );
  };

  const submitLinkedSurveyHandler = () => {
    const name = form[TITLE].value;
    const code = form[CODE].value;
    const description = form[DESC].value;
    if (!team_id) {
      return setWarning(TEXT.noTeamId);
    }

    if (!name) {
      return updateForm(TITLE, { value: '', errorText: TEXT.isRequired });
    }
    if (!cat?.value) {
      return setWarning('Category is required');
    }
    if (!code) {
      return updateForm(CODE, { value: '', errorText: TEXT.isRequired });
    }
    const assQError = 'In assessment should be at least one question';
    const assLSError = 'Please, enter the correct link with https:// or  http:// and the domain name';
    if (!questions[0]) {
      if (answer_type === EQuestionType.linked_survey) {
        return setWarning(assLSError);
      } else {
        return setWarning(assQError);
      }
    }
    if (!questions[0].title) {
      if (answer_type === EQuestionType.linked_survey) {
        return setWarning(assLSError);
      } else {
        return setWarning(assQError);
      }
    }

    const formatSQ: TQuestion[] = questions.map((q, i) => ({
      index: i + 1,
      body: {
        en_US: q.title,
      },
      answer_type: q.answer_type,
    }));

    const data: TAssessmentCreate = {
      name: {
        en_US: name,
      },
      description: {
        en_US: description,
      },
      type: typeSelect.value,
      code,
      team_id,
      answer_type: EQuestionType.linked_survey,
      category_id: cat.value,
      questions: formatSQ,
    };

    if (assessment) {
      console.log('Assessment here');
      console.log(assessment);
      console.log(data);
      return dispatch(
          EditAssessment.request({
            data,
            id: assessment.id,
            callBack: (success, data) => {
              const newData = callBackEdit({
                success,
                data,
                cats,
                assessment,
                name,
              });
              if (!newData) return null;
              const { newCasts, ass } = newData;
              if (Packages.current) updatePack(ass);
              dispatch(setAssEditMode(false));
              dispatch(EditAssessment.success(newCasts));
              updateAssInPackage(ass);
              return dispatch(GetOneAssessment.success({}));
            },
          })
      );
    }

    return dispatch(
        CreateAssessment.request({
          data,
          callBack: (success, data) => {
            const newData = callBackCreate({
              success,
              data,
              cats,
              name,
              questions,
            });
            if (!newData) return null;
            const { newCasts } = newData;
            dispatch(CreateAssessment.success(newCasts));
            return closeModal();
          },
        })
    );
  };

  return {
    textAnonymousHandler,
    textOr1To5Handler,
    addQuestion,
    addQuestionLinkSurvey,
    questions,
    removeQuestion,
    titleHandler,
    removePass,
    reverseHandler,
    form,
    TITLE,
    DESC,
    onChangeHandler,
    updateForm,
    submitHandler,
    submitLinkedSurveyHandler,
    cat,
    setCat,
    CODE,
    typeSelect,
    setTypeSelect,
    warning,
  };
};
